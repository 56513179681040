import { useRef } from 'react';
import { createRoot } from 'react-dom/client';
import ReCAPTCHA from 'react-google-recaptcha';
import Dialog from 'epn-ui/umd/dist/components/Dialog';
import ErrorBoundary from '@components/base/BaseErrorBoundary';

import getLocaleFromUrl from '@utils/urls/getLocaleFromURL';

const TITLE_LOCALE = {
    en: 'Please complete the verification',
    ru: 'Пройдите проверку',
};

interface IBaseCaptcha {
    siteKey: string;
    onCaptcha: (string) => void;
    onError: () => void;
    onClose: () => void;
}

const BaseCaptcha: React.FC<IBaseCaptcha> = ({ siteKey, onCaptcha, onError, onClose }) => {
    const recaptchaRef = useRef<ReCAPTCHA>();
    const lang = getLocaleFromUrl();
    const title = TITLE_LOCALE[lang];

    if (!siteKey) return null;

    const onExpired = () => {
        recaptchaRef.current.reset();
    };

    const onChange = token => {
        if (!token) {
            onExpired();
        }
        onCaptcha(token);
    };

    return (
        <Dialog isOpen title={title} onClose={onClose} id="BaseReCaptcha" size="sm">
            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={siteKey}
                onChange={onChange}
                onExpired={onExpired}
                onErrored={onError}
                hl={lang}
            />
        </Dialog>
    );
};

const ReCaptchaModal = (siteKey: string): Promise<unknown> =>
    new Promise((resolve, reject) => {
        const block = document.createElement('div');
        block.setAttribute('id', 'recaptcha-container');
        block.style.position = 'absolute';
        block.style.zIndex = '10000';
        document.body.appendChild(block);

        const root = createRoot(block);

        const unMount = () => {
            root.unmount();
            document.body.removeChild(block);
        };

        const onClose = () => {
            unMount();
            resolve('closed');
        };
        const onCaptcha = (token: string) => {
            unMount();
            resolve(token);
        };

        root.render(
            <ErrorBoundary>
                <BaseCaptcha
                    siteKey={siteKey}
                    onCaptcha={onCaptcha}
                    onError={reject}
                    onClose={onClose}
                />
            </ErrorBoundary>,
            block,
        );
    });

export default ReCaptchaModal;
