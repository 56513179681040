import useI18n from '@i18n';

import externalLinks from '@config/external-links.json';
import BaseSemanticLink from '@base/BaseSemanticLink';
import BaseContainer from '@base/BaseContainer';

import { companyName } from '@config/globalDomain';
import I18nLink from './I18nLink';

import WithdrawalMethods from './WithdrawalMethods';
import { IFooterLinkItem } from './interfaces';

import links from './config';

const contacts: IFooterLinkItem[] = {
    epn: [
        {
            key: 'Support',
            id: 'FooterSupportLink',
            href: externalLinks.mail,
        },
        {
            key: 'Your suggestions',
            id: 'FooterQualityLink',
            href: externalLinks.quality,
        },
    ],
    epncpa: [],
}[process.env.NEXT_PUBLIC_PROJECT_NAME];

const Footer: React.FC<{ isAuth: boolean | null }> = ({ isAuth }) => {
    const { t, locale } = useI18n();

    const hideChangeLang = process.env.NEXT_PUBLIC_PROJECT_LOCALES.split(',').length < 2;

    return (
        <footer className="pb-6 border-t border-blueGray-200">
            <BaseContainer>
                <div className="flex flex-col mb-12 xl:flex-row xl:justify-between xs:mb-8 sm:mb-12 xl:mb-10">
                    <div className="mb-8">
                        <div className="flex flex-wrap">
                            {links.map((link: IFooterLinkItem) => {
                                if (typeof link.isVisible === 'boolean' && !link.isVisible)
                                    return null;
                                if (link.isAuth && link.isAuth !== isAuth) return null;

                                return (
                                    <span
                                        className="mt-6 mr-6 whitespace-nowrap last:mr-0 text-blueGray-800"
                                        key={link.key}
                                    >
                                        <BaseSemanticLink
                                            className="hover:underline"
                                            id={link.id}
                                            to={link.href.replace(':locale', locale)}
                                        >
                                            {t(link.key, { companyName })}
                                        </BaseSemanticLink>
                                    </span>
                                );
                            })}
                        </div>
                        <div className="flex flex-wrap">
                            {contacts &&
                                contacts.map(contact => (
                                    <span
                                        className="mt-6 mr-6 whitespace-nowrap last:mr-0"
                                        key={contact.key}
                                    >
                                        <span className="text-blueGray-500 text-p1">
                                            {t(contact.key)}:{' '}
                                        </span>
                                        <BaseSemanticLink
                                            className="text-blueGray-800 hover:underline"
                                            id={contact.id}
                                            to={`mailto: ${contact.href}`}
                                        >
                                            {contact.href}
                                        </BaseSemanticLink>
                                    </span>
                                ))}
                        </div>
                    </div>
                    <div className="flex items-center h-full mt-6">
                        {/* <Social /> */}
                        {!hideChangeLang && <I18nLink />}
                    </div>
                </div>

                <div className="flex flex-col items-center xl:flex-row xl:justify-between">
                    <div className="w-full mb-2 xs:mb-10 sm:mb-6 xl:mb-0 xl:max-w-1/2">
                        <WithdrawalMethods />
                    </div>
                    <p className="text-center text-p2 text-blueGray-600 xl:text-right">
                        ＠ {new Date().getFullYear()} {companyName}
                    </p>
                </div>
            </BaseContainer>
        </footer>
    );
};

export default Footer;
