import config from '@config/auth.json';
import setAuthUser from '@utils/tokenManipulation/setUserAuth';
import getRefreshToken from '@utils/tokenManipulation/getRefreshToken';

const isDev = process.env.NODE_ENV === 'development';

const refreshToken = async (client, clearRefreshPromise) => {
    const result = await client.post('token/refresh', {
        prefixUrl: process.env.NEXT_PUBLIC_OAUTH2_PREFIX,
        json: {
            grant_type: 'refresh_token',
            refresh_token: getRefreshToken(),
            client_id: isDev ? config.LOCAL_CLIENT_ID : config.CLIENT_ID,
            client_secret: isDev ? config.LOCAL_CLIENT_SECRET : config.CLIENT_SECRET,
        },
        ignoreAuth: true,
        ignoreRefresh: true,
    });
    const { data } = await result.json();
    const { attributes } = data;
    setAuthUser(attributes.access_token, attributes.refresh_token);
    clearRefreshPromise();
};

export default refreshToken;
