import { differenceInYears } from 'date-fns';
import isFinite from 'lodash/isFinite';
import * as Sentry from '@sentry/nextjs';
import api from '@api';
import { getSumWithCurrency, getPercent } from '@utils/formatters/d3Formatters';

import MirIcon from '@base/BaseWallet/images/mir.svg';
import MasterCardIcon from '@base/BaseWallet/images/mastercard.svg';
import VisaIcon from '@base/BaseWallet/images/visa.svg';
import WmzIcon from '@base/BaseWallet/images/wmz.svg';
import YoomoneyIcon from '@base/BaseWallet/images/yoomoney.svg';
import QiwiIcon from '@base/BaseWallet/images/qiwi.svg';
import EntryprofitIcon from '@base/BaseWallet/images/entryprofit.svg';
import { getCurrencySign } from '@utils/currency';
import TonIcon from '@base/BaseWallet/images/ton.svg';
import Sbp from '@base/BaseWallet/images/sbp.svg';

import {
    regexpValidation,
    MINIMAL_AGE,
    walletsWithEnCities,
    walletsTypes,
    PAYMENT_TYPES,
    VISA_PATTERN,
    MASTERCARD_PATTERN,
    banksForSBP,
} from './config';

const regexNotDecimal = /0(x|b|o)[\da-f]+/i;

/**
 * Проверяет на число исключая NaN, Infinite, '', []
 * @example
 * isNumber('123')
 * // returns true
 * @example
 * isNumber('06.56')
 * // returns true
 * @example
 *  * isNumber('0xFFFFFF')
 * // returns false
 * @example
 *  * isNumber(0x0062)
 * // returns true
 * @returns {Boolean}
 */
export const isNumber = value =>
    value !== '' &&
    !Number.isNaN(+value) &&
    isFinite(+value) &&
    !regexNotDecimal.test(value) &&
    (typeof value === 'string' || typeof value === 'number');

export const getCitiesFormatted = async (countryCode, walletType) => {
    const params = {
        urlParams: { countryCode },
    };

    if (walletsWithEnCities.includes(walletType)) params.headers = { 'Accept-Language': 'en' };

    const { data } = await api.geo.getCities(params);
    return (
        data?.map(({ attributes: { name } }) => ({
            value: name,
            label: name,
        })) || []
    );
};

export const prepareBirthDate = date => {
    if (!date) return '';
    const birthDay = date.substr(0, 2);
    const birthMonth = date.substr(3, 2);
    const birthYear = date.substr(6, 4);
    return `${birthYear}-${birthMonth}-${birthDay}`;
};

export const getExpireMonthYear = cardExpire => {
    const [, expMonth = null, expYear = null] =
        cardExpire?.match(regexpValidation.cardExpire) || [];
    return [expMonth, expYear];
};

export const getValue = (selectOptons, optionId) =>
    selectOptons?.find(option => option.value === optionId) || null;

// валидаторы
export const birthDateValidation = (date, t) => {
    const youMustBeOverMinimalAgeMessage = t('You must be over 14');
    const wrongFormatMessage = t(
        'Please make sure date of birth matches the required format: DD.MM.YYYY',
    );
    if (regexpValidation.date.test(date)) {
        const splittedDate = date.split('.');
        const [day, month, year] = splittedDate;
        const dateOfBirth = new Date(`${year}/${month}/${day}`);
        const isMoreThanMinimalAge = differenceInYears(new Date(), dateOfBirth) >= MINIMAL_AGE;
        return isMoreThanMinimalAge || youMustBeOverMinimalAgeMessage;
    }
    return wrongFormatMessage;
};

export const isValidCardNumber = cardNumberValue => {
    const cardNumber = cardNumberValue.replace(/[- ]+/g, '');

    let sum = 0;
    let flag = 0;

    for (let i = cardNumber.length - 1; i >= 0; i--) {
        // eslint-disable-next-line
        const add = flag++ & 1 ? cardNumber[i] * 2 : cardNumber[i];
        sum += Number(add) > 9 ? Number(add) - 9 : Number(add);
    }

    return sum % 10 === 0;
};

export const bankCardValidation = (cardNumberValue, t) => {
    if (!isValidCardNumber(cardNumberValue)) {
        return t('Invalid card number');
    }

    return true;
};

export const cardExpValidation = (date, t) => {
    const [expMonth, expYear] = getExpireMonthYear(date);

    const currentYear = new Date().getFullYear();

    let isEnteredMonthMoreOrEqualCurrent = true;

    if (Number(`20${expYear}`) === currentYear) {
        const nextMonth = new Date().getMonth() + 1;
        isEnteredMonthMoreOrEqualCurrent = Number(expMonth) >= nextMonth;
    }

    const isEnteredYearMoreOrEqualCurrent = Number(`20${expYear}`) >= currentYear;
    const isEnteredYearLess20Years = Number(`20${expYear}`) < currentYear + 20;
    const isEnteredYearBellow10Years = Number(`20${expYear}`) > currentYear - 10;

    const isCardExpireDateValid =
        (isEnteredMonthMoreOrEqualCurrent &&
            isEnteredYearMoreOrEqualCurrent &&
            isEnteredYearLess20Years) ||
        isEnteredYearBellow10Years;

    return isCardExpireDateValid || t('Invalid entire period of the card');
};

export const removeWhitespaces = str => str.replace(/\s/g, '');

export const getFormattedCommission = (pursesInfo, walletType) => {
    const walletCurrency = walletType === walletsTypes.bank131CardRussia ? 'EUR' : 'USD';
    const commissionInfo = pursesInfo?.[walletType]?.info[walletCurrency];
    const { min = 0, commissionPercent = 0, commissionFix = 0, minCommissionFix = 0 } =
        commissionInfo || {};

    return {
        commissionMin: getSumWithCurrency(min, 'USD', true),
        minCommissionFix: minCommissionFix && getSumWithCurrency(minCommissionFix, 'USD', true),
        commissionFix: commissionFix && getSumWithCurrency(commissionFix, walletCurrency, true),
        commissionPercent: getPercent(commissionPercent),
    };
};

/**
 *
 * @param {string} cardNumber
 * @returns {string}
 */
export const getPaymentSystem = cardNumberValue => {
    if (!cardNumberValue || cardNumberValue.length < 13) {
        return null;
    }

    if (!isValidCardNumber(cardNumberValue)) {
        return null;
    }

    const cardNumberWithoutSpace = cardNumberValue.replaceAll(' ', '');

    if (cardNumberWithoutSpace.match(MASTERCARD_PATTERN)) {
        return PAYMENT_TYPES.MASTERCARD;
    }

    if (cardNumberWithoutSpace.match(VISA_PATTERN)) {
        return PAYMENT_TYPES.VISA;
    }

    return null;
};

/**
 * @param {*} pursesInfo
 * @param {string} walletType
 * @returns { min: string; max: string }
 */
export const getMinWithdrawValuesByPurseType = (pursesInfo, walletType) => {
    const defaultValuesResult = { min: '', max: '' };
    try {
        if (!pursesInfo) return defaultValuesResult;
        if (typeof walletType !== 'string') return defaultValuesResult;
        if (walletType.length === 0) return defaultValuesResult;

        const purse = pursesInfo[walletType] || null;
        if (!purse) return defaultValuesResult;
        if (!purse.info) return defaultValuesResult;

        const currencyArray = Object.keys(purse.info);
        if (currencyArray.length === 0) return defaultValuesResult;

        const minResults = currencyArray.map(cur => {
            const sign = getCurrencySign(cur, 'sign');
            return `${cur} - ${purse.info[cur]?.min} ${sign}`;
        });

        const maxResults = currencyArray.map(cur => {
            const sign = getCurrencySign(cur, 'sign');
            return `${cur} - ${purse.info[cur]?.max} ${sign}`;
        });

        return { min: minResults.join('; '), max: maxResults.join('; ') };
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                pursesInfo: JSON.stringify(pursesInfo),
                walletType: JSON.stringify(walletType),
            },
        });

        return defaultValuesResult;
    }
};

// TODO: вынести отдельно для переиспользования в WithdrawModal
export const getPayoutComissionMessage = (t, walletType, pursesInfo) => {
    const { minCommissionFix, commissionFix, commissionPercent } = getFormattedCommission(
        pursesInfo,
        walletType,
    );

    const commission = commissionFix
        ? `${commissionPercent} + ${commissionFix}`
        : commissionPercent;

    if (minCommissionFix) {
        return t('Payout commission with no less', {
            commission,
            minCommissionFix,
        });
    }

    return commissionPercent === '0%' ? t('No fees') : t('Payout commission value', { commission });
};

export const getWalletsButtons = t => [
    {
        icons: [WmzIcon],
        name: 'WMZ',
        type: walletsTypes.wmz,
    },
    {
        icons: [TonIcon],
        name: 'Toncoin',
        type: walletsTypes.ton,
    },
    {
        icons: [QiwiIcon],
        name: 'QIWI',
        type: walletsTypes.qiwi,
    },
    {
        icons: [EntryprofitIcon],
        name: 'EntryProfit',
        type: walletsTypes.entryProfit,
    },
    {
        icons: [YoomoneyIcon],
        name: t('YooMoney'),
        type: walletsTypes.yandexMoney,
    },
    {
        icons: [MasterCardIcon, VisaIcon],
        name: t('Wire transfer'),
        type: walletsTypes.wiretransfer,
    },
    {
        icons: [MasterCardIcon, VisaIcon],
        name: `${t('Ukraine')} ${t('RF')} (USD)`,
        type: walletsTypes.bankCardUkraine,
    },
    {
        icons: [MasterCardIcon, VisaIcon],
        name: `${t('Bank Card')} (${t('Kazakhstan')})`,
        type: walletsTypes.bankCardKazakhstan,
    },
    {
        icons: [MasterCardIcon, VisaIcon],
        name: `${t('Ukraine')} (UAH)`,
        type: walletsTypes.bankCardUkraineUah,
    },
    {
        icons: [MasterCardIcon, VisaIcon, MirIcon],
        name: `${t('Bank Card')} (${t('Russia')})`,
        type: walletsTypes.bank131CardRussia,
    },
    {
        icons: [MasterCardIcon],
        name: t('Rest of the World cards(shortened)'),
        type: walletsTypes.restOfTheWorldCards,
    },
    {
        icons: [MasterCardIcon, VisaIcon],
        name: t('World cards (USD)'),
        type: walletsTypes.bankCardUsd,
    },
    {
        icons: [Sbp],
        name: t('SBP'),
        type: walletsTypes.fps,
    },
];

export const getNameOfBankForSBP = bankId => {
    return banksForSBP.find(bank => +bankId === +bank.id);
};

export const getOptionsFromBanksForSBP = locale =>
    banksForSBP.map(bank => ({
        label: locale === 'ru' ? bank.ru_name : bank.eng_name,
        value: bank.id,
    }));
