import Cookies from 'js-cookie';
import clearLocalStorage from '@utils/browserDataManipulators/clearLocalStorage';

const isDev = process.env.NODE_ENV === 'development';

const clearSession = (): void => {
    // Для подмены домена кукисов при разработке
    Cookies.remove('jwt_access_token', {
        path: '/',
        domain: isDev ? 'localhost' : `.${process.env.NEXT_PUBLIC_COOKIES_DOMAIN}`,
    });
    Cookies.remove('jwt_refresh_token', {
        path: '/',
        domain: isDev ? 'localhost' : `.${process.env.NEXT_PUBLIC_COOKIES_DOMAIN}`,
    });
    Cookies.remove('new_user');

    clearLocalStorage();
};

export default clearSession;
