export const CYRILLIC_PATTERN = /[А-яЁё]/gm;

export const WITH_ONE_SPACE_PATTERN = /^\S+(\s\S+)*$/gm;
// Допускается не более одного пробела подряд
export const onlyOneDashAllowRegex = new RegExp('^[^-]*-?[^-]*$', 'gm');

export const PHONE_PATTERN = /^((\+[0-9]|[0-9])+([0-9]){9,19})$/;

export const WEBSITES_PATTERN = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;

export const LATIN_PATTERN = /^[A-Za-z\s]+$/gm;

// Без пробела в начале и в конце строки
export const WITHOUT_SPACE_START_END = /^[a-zA-Zа-яёА-ЯЁ0-9_](.*\S)?$/gm;

// TODO: move here all regexp and share for all forms
