import setJWTTokensForDev from '@utils/tokenManipulation/setJWTTokensForDev';
import getDataFromTokenPayload from '@utils/tokenManipulation/getDataFromTokenPayload';

const isDev = process.env.NODE_ENV === 'development';

const setAuthUser = (access_token: string, refresh_token: string): void => {
    // Проверка на роль кабинета
    if (access_token && !isDev) {
        const role = getDataFromTokenPayload('user_role');
        const roles_white_list = ['user', 'admin', 'support', 'partner'];
        // Проверка, что role не undefined и приведение к типу string
        if (typeof role !== 'string') {
            throw new Error('Role is null or not a string');
        }
        if (!roles_white_list.includes(role)) {
            throw new Error('Role restricted');
        }
    }

    if (isDev && refresh_token && access_token) {
        setJWTTokensForDev(access_token, refresh_token);
    }
};

export default setAuthUser;
