import Cookies from 'js-cookie';

/**
 * Get data from access token
 * @param {string} key
 * @returns {T | null}
 */
const getDataFromTokenPayload = <T>(key: string): T | null => {
    if (typeof key !== 'string') return null;
    if (key.length === 0) return null;

    try {
        const accessToken: string | undefined = Cookies.get('jwt_access_token');
        if (!accessToken) return null;

        const token = JSON.parse(atob(accessToken.split('.')[1]));

        return token[key] || null;
    } catch {
        return null;
    }
};

export default getDataFromTokenPayload;
