// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import getDataFromTokenPayload from '@utils/tokenManipulation/getDataFromTokenPayload';

const { name, version } = require('./package.json');

const enabled =
    (process.env.NEXT_PUBLIC_IS_DEV === 'true' || process.env.NODE_ENV === 'development') &&
    process.env.SENTRY_AUTH_TOKEN !== 'undefined';
const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

if (!enabled) {
    Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [new Sentry.BrowserTracing()],
        release: `${name}@${version}`,
        environment: process.env.NODE_ENV,
        tracesSampleRate: 1.0,
        ignoreErrors: [
            // Random plugins/extensions
            'top.GLOBALS',
            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
            'originalCreateNotification',
            'canvas.contentDocument',
            'MyApp_RemoveAllHighlights',
            'http://tt.epicplay.com',
            "Can't find variable: ZiteReader",
            'jigsaw is not defined',
            'ComboSearch is not defined',
            'http://loading.retry.widdit.com/',
            'atomicFindClose',
            // Facebook borked
            'fb_xd_fragment',
            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
            'bmi_SafeAddOnload',
            'EBCallBackMessageReceived',
            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            'conduitPage',
            'Request failed with status code 401',
            'Request failed with status code 403',
            'Request failed with status code 403 Forbidden',
            'jQuery is not defined',
            /^(Non-Error promise rejection captured with value:\s)(Object Not Found Matching Id:\d{1,})$/i,
        ],
        denyUrls: [
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            /chrome-extension:/i,
            // Other plugins
            /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
            /metrika\/tag/i, // yandex metrika
        ],
    });

    Sentry.setUser({
        id: getDataFromTokenPayload('user_id'),
        user_role: getDataFromTokenPayload('user_role'),
    });
}
