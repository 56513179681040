import * as Sentry from '@sentry/nextjs';

const permanentLocalStorageKeys = ['isHeaderTourOpen', 'currency'];
const clearLocalStorage = (): void => {
    try {
        Object.keys(localStorage).forEach((value: string): void => {
            if (!permanentLocalStorageKeys.includes(value)) {
                localStorage.removeItem(value);
            }
        });
    } catch (e) {
        Sentry.captureException(e);
        localStorage.clear();
    }
};

export default clearLocalStorage;
