import { useEffect, useState } from 'react';
import Dropdown, { DropdownItem } from 'epn-ui/umd/dist/components/Dropdown';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';

import BaseSemanticLink from '@base/BaseSemanticLink';
import useI18n from '@i18n';
import { breakpoints } from 'src/theme';
import useWindowSize from '@hooks/useWindowSize';
import { ITabPanelItem } from '../interfaces';

export interface ITabDropdownProps {
    items: ITabPanelItem[];
    className: string;
}

const TabDropdown: React.FC<ITabDropdownProps> = ({ items, className }) => {
    const { t } = useI18n();
    const { width: windowWidth } = useWindowSize();
    const [menuItems, setMenuItems] = useState<DropdownItem[]>([]);

    useEffect(() => {
        if (Array.isArray(items) && items.length !== 0) {
            const result = [];

            items.forEach((item: ITabPanelItem) => {
                if (!breakpoints[item.screen] || windowWidth < breakpoints[item.screen]) {
                    let childrens;

                    if (Array.isArray(item.childrens) && item.childrens.length !== 0) {
                        childrens = item.childrens.map((childItem: ITabPanelItem) => ({
                            key: childItem.title,
                            value: (
                                <BaseSemanticLink to={childItem.href}>
                                    {childItem.title}
                                </BaseSemanticLink>
                            ),
                        }));
                    }

                    result.push({
                        key: item.title,
                        value: item.href ? (
                            <BaseSemanticLink to={item.href}>{item.title}</BaseSemanticLink>
                        ) : (
                            item.title
                        ),
                        childrens,
                    });
                }
            });

            setTimeout(() => {
                setMenuItems(result);
            }, 1000);
        }
    }, [items, windowWidth]);

    return (
        <Dropdown
            className={`${className} ignore-more-info`}
            items={menuItems}
            getPopupContainer={(node: HTMLElement) => node.parentNode as HTMLElement}
        >
            <button type="button">
                <span className="inline-block mr-1">{t('More')}</span>
                <FontAwesomeIcon icon={faChevronDown} />
            </button>
        </Dropdown>
    );
};

export default TabDropdown;
