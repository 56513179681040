import { formatLocale } from 'd3-format';

// 1002323823885.34256 -> 1 002 323 823 885.34256
const locale = formatLocale({
    thousands: '\u00a0', // неразрывный пробел между тысячными
    grouping: [3], // группировка по 3 цифры
});

export const getSumWithCurrency = (sum, currency, rounded = false) => {
    const currencies = {
        USD: ['$\u00a0', ''], // 23885.34256 -> $ 23 885.34
        RUB: ['', '₽'], // 23885.34256 -> 23 885.34₽
        EUR: ['€\u00a0', ''], // 23885.34256 -> € 23 885.34
        GBP: ['£\u00a0', ''], // 23885.34256 -> £ 23 885.34
        TON: ['', 'TON'],
    };

    // если rounded === true, используем формулу с округлением до значащей цифры:
    // 3885.00 -> $ 3 885
    // 1325.30 -> $ 1 325.3
    // 2106.06 -> $ 2 106.06
    const formula = rounded ? '$,.2~f' : '$,.2f';

    return formatLocale({
        thousands: '\u00a0', // неразрывный пробел между тысячными
        grouping: [3], // группировка по 3 цифры
        currency: currencies[currency],
    }).format(formula)(sum);
};

/**
 * % - умножает на 100, поэтому нужно предварительно разделить
 * 0.60006543 -> 0.6%
 * @param {number} number
 * @returns {string}
 */
export const getPercent = number => {
    if (Number.isNaN(parseInt(number, 10))) return '—';

    return locale.format('~%')(number / 100);
};
