/**
 * Проверяет срок жизни токена, возращает true, если токен просрочен
 * @param {string} token
 * @returns {boolean}
 */
const jwtTokenIsExpired = (token: string): boolean => {
    try {
        if (!token) return true;
        const parsedToken = JSON.parse(atob(token.split('.')[1]));
        const expTime = parsedToken.exp * 1000;
        const nowTime = Date.now();
        return nowTime >= expTime;
    } catch (error) {
        console.error(`Parsed token error: ${error}`);
        return true;
    }
};

export default jwtTokenIsExpired;
