import useOnclickOutside from 'react-cool-onclickoutside';
import isEmpty from 'lodash/isEmpty';
import Button from 'epn-ui/umd/dist/components/Button';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';

import useI18n from '@i18n';
import apiHooks from '@apiHooks';
import { IGetAlertsDataFormatted } from '@api/types';
import useLocalStorage from '@hooks/useLocalStorage';

const { useAlerts } = apiHooks;

const Alerts: React.FC = () => {
    const { locale, t } = useI18n();

    const [isVisibleAlerts, setIsVisibleAlerts] = useLocalStorage('isVisibleAlerts', true);
    const alertsRef = useOnclickOutside(
        () => {
            setIsVisibleAlerts(false);
        },
        { ignoreClass: 'ignore-alerts' },
    );

    const { data: alerts = [] } = useAlerts<IGetAlertsDataFormatted[]>();

    if (isEmpty(alerts)) {
        return null;
    }

    const alertsList = alerts.map(({ id, message }) => (
        <li
            key={id}
            className="w-full px-6 py-4 border-b border-blueGray-200 last:border-b-0 text-p2 xs:text-p1 xs:w-auto"
        >
            {message[locale]}
        </li>
    ));

    return (
        <div className="fixed bottom-0 right-0 z-50 m-2 sm:m-4">
            {isVisibleAlerts && (
                <div
                    id="AlertsList"
                    className="absolute right-0 h-24 mb-2 overflow-hidden bg-white rounded-3 shadow-1 xs:h-auto"
                    // вычитаем отступ от границ экрана для получения максимальной ширины
                    style={{ bottom: '100%', width: '400px', maxWidth: 'calc(100vw - 16px)' }}
                >
                    <div className="absolute bottom-0 w-full h-6 bg-gradient-to-t from-white" />
                    <ul className="h-full pb-2 overflow-y-auto overscroll-contain" ref={alertsRef}>
                        {alertsList}
                    </ul>
                </div>
            )}
            <Button
                id="AlertsButton"
                type="primary"
                className="ignore-alerts"
                icon={faExclamationCircle}
                iconPosition="left"
                onClick={() => {
                    setIsVisibleAlerts(!isVisibleAlerts);
                }}
            >
                {/* если ошибок больше 1 то показываем количество */}
                {alerts.length > 1 ? t('Alerts', { count: alerts.length }) : t('Alert')}
            </Button>
        </div>
    );
};

export default Alerts;
