import getLocaleFromUrl from '@utils/urls/getLocaleFromURL';
import config from '@config/auth.json';
import { getRefreshToken } from '@utils/tokenManipulation';
import { clearLocalStorage, clearSession } from '@utils/browserDataManipulators';
import api from '../../store/api';

const isDev = process.env.NODE_ENV === 'development';

// eslint-disable-next-line import/prefer-default-export
export const logout = async (): Promise<void> => {
    try {
        const refreshToken = getRefreshToken();
        const body = {
            client_id: 'web-client',
            refresh_token: refreshToken,
        };

        await api.logout.logout({ body });

        clearSession();

        if (typeof window !== 'undefined') {
            const lang = getLocaleFromUrl();
            clearLocalStorage();
            window.location.href = isDev ? '/ru/auth' : config.AUTH_URL.replace(':locale', lang);
        }
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    }
};
