import { useState, useEffect } from 'react';
import { NextComponentType } from 'next';
import { getRefreshToken } from '@utils/tokenManipulation';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useRefreshToken = (dependencies: NextComponentType[] = []): boolean => {
    const [isAuth, setAuth] = useState(false);

    // важно считывать куки через useEffect изза этой ошибки:
    // https://github.com/vercel/next.js/discussions/17443#discussioncomment-87097
    useEffect(() => {
        const refreshToken = getRefreshToken();
        if (!refreshToken) {
            setAuth(false);
            return;
        }
        setAuth(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);

    return isAuth;
};

export default useRefreshToken;
